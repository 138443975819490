.App {
  text-align: center;
  background-color: "#34b4eb";
}

.repoContainer {
  border-bottom: 1px solid rgb(225, 228, 232);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.repoName {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #0366d6;
  text-size-adjust: 100%;
  text-decoration: none;
}

.repoName:hover {
  text-decoration: underline;
}

.repoDescription {
  color: #586069;
}

.JavaScript {
  height: 12;
  width: 12;
  background-color: #f1e05a;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.Ruby {
  height: 12;
  width: 12;
  background-color: #701516;
  border-radius: 50%;
  display: inline-block;
}
